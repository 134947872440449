/* website: 2374-westernmazda.scss
 * created at 2020-07-22 16:10 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/mazda.scss";
@import "../utils/icons.scss";
.widget-sr{
  .calculator-tradeIn{
    .sr-link{
      display: none !important;
    }
  }
}